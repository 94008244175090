<template>
    <modal ref="modalEditarActividad" titulo="Crear actividad" tamano="modal-lg" adicional="Guardar" @adicional="editarActividad">
        <div class="row mx-3 my-3 f-15">
            <div class="col-12">
                <p class="input-label-top">Nombre de la actividad</p>
                <el-input v-model="nombreGrupo" class="w-100" size="small" />
            </div>
        </div>
        <div class="row mx-3 my-3 f-15">
            <div class="col-4 my-auto pt-2">
                <el-checkbox v-model="checkedActividadProdecesora">Actividad predecesora</el-checkbox>
            </div>
            <div class="col-4">
                <p class="input-label-top">Fecha en duración de la actividad</p>
                <el-date-picker v-model="valueFechaDuracion" type="date" placeholder="Seleccionar fecha" class="w-100" size="small" />
            </div>
        </div>
        <div class="row mx-3 my-3 f-15">
            <div class="col-8">
                <p class="input-label-top">Nombre de la meta</p>
                <el-input v-model="nombreMeta" class="w-100" size="small" />
            </div>
            <div class="col-4">
                <p class="input-label-top">Unidades de la meta</p>
                <el-input v-model="unidadMeta" class="w-100" size="small" />
            </div>
        </div>
        <div class="row mx-3 my-3 f-15">
            <div class="col-8">
                <p class="input-label-top">Descripción</p>
                <el-input v-model="descripcion" type="textarea" class="w-100" size="small" :autosize="{ minRows: 4, maxRows: 6}" />
            </div>
            <div class="col-4">
                <chrome-picker v-model="color" />
            </div>
        </div>
    </modal>
</template>

<script>
import { Chrome } from 'vue-color'
export default {
    components:{
        'chrome-picker': Chrome
    },
    data(){
        return {
            checkedActividadProdecesora: false,
            nombreGrupo: '',
            valueFechaDuracion: '',
            nombreMeta: '',
            unidadMeta: '',
            descripcion: '',
             color: '#0b56a7',
        }
    },
    methods: {
        toggle(){
            this.$refs.modalEditarActividad.toggle()
        },
        editarActividad(){
            this.toggle()
        }
    }
}
</script>